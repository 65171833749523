<template>
  <land-section
    id="my_album_header"
    space="8"
  >
    <v-container
      style="max-width: 1200px;"
    >
      <v-row>
        <v-col
          cols="12"
          md="4"
          sm="12"
          class="hidden-sm-and-down"
        >
          <span class="co-text-sm co-text-gray-600">
            {{ `${info.imageTotal || 0} 张作品` }}
          </span>
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="12"
        >
          <div class="co-flex-col co-justify-start co-items-center">
            <span class="co-text-md co-font-600 mb-3">
              {{ info.title }}
            </span>
            <v-avatar
              size="64"
            >
              <v-img
                :src="userInfo.avatar"
              />
            </v-avatar>
            <span class="co-text-sm my-2">
              {{ userInfo.username }}
            </span>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="12"
        >
          <div
            class="co-flex-row co-justify-end co-items-center co-w-full"
            :class="[smAndDown ? 'co-justify-center' : 'co-justify-end']"
          >
            <pop-menu
              :iconed="btnScope.iconed"
              :texted="btnScope.texted"
              :text="btnScope.text"
              :prefix-icon="btnScope.prefixIcon"
              :actions="btnScope.items"
              @action="changeScope"
            />
            <v-btn
              outlined
              color="blue-grey dark-3"
              class="ml-4"
              @click="onAdd"
            >
              {{ btns.addText }}
            </v-btn>
            <v-btn
              color="primary"
              class="ml-4"
              @click="onEdit"
            >
              {{ btns.editText }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <album-info ref="dlgAlbumInfo" />
    <dialog-works
      ref="dlgWorks"
      @confirm="onConfirmWorks"
    />
  </land-section>
</template>

<script>
  import user from '@/api/co.user'
  import web from '@/api/web/co.web'
  export default {
    components: {
      PopMenu: () => import('@/pages/sections/comm/PopMenu.vue'),
      DialogWorks: () => import('@/pages/sections/comm/DialogWorks.vue'),
      AlbumInfo: () => import('@/pages/sections/album/AlbumInfo.vue'),
    },
    props: {
      item: {
        type: Object,
        default: () => {
          return {}
        }
      },
    },
    data () {
      return {
        info: {},
        userInfo: {},
        btnScope: {
          icon: 'mdi-chevron-down',
          text: '公开',
          value: '',
          prefixIcon: '',
          iconed: false,
          texted: true,
          items: []
        },
        btns: {
          addText: '从作品中添加',
          editText: '编辑影集'
        }
      }
    },
    computed: {
      smAndDown () {
        return this.$vuetify.breakpoint.smAndDown
      }
    },
    watch: {
      item: {
        handler (val) {
          this.info = Object.assign({}, val)
        },
        immediate: true
      }
    },
    created () {
      this.userInfo = user.info.get()
      this.configScopes()
    },
    methods: {
      onConfirmWorks (imageList) {
        this.$emit('add-works', imageList)
      },
      reset (ev) {
        this.info = Object.assign({}, this.info, ev)
      },
      onAdd () {
        const dlg = this.$refs.dlgWorks
        if (dlg) {
          dlg.show()
        }
      },
      onEdit () {
        const dlg = this.$refs.dlgAlbumInfo
        if (dlg) {
          dlg.show(this.info)
        }
      },
      configScopes () {
        this.btnScope.items = []
        const descs = web.comm.ScopeDescs
        for (const key in descs) {
          const item = descs[key]
          this.btnScope.items.push(item)
        }
        const first = this.btnScope.items[0]
        this.setScope(first)
      },
      setScope (item) {
        this.btnScope.text = item.text
        this.btnScope.value = item.value
        this.btnScope.prefixIcon = item.icon
      },
      changeScope (ev) {
        this.setScope(ev.item)
      },
    }
  }
</script>
